<template>
  <div class="price-list-container">
    <!-- 搜索条件区域 -->
    <div class="search-section">
      <el-form :model="searchForm" inline>
        <el-form-item label="门店">
          <el-select-v2
            v-model="searchForm.storeCode"
            :options="storeOptions"
            placeholder="请选择门店"
            class="search-select userSelect"
            filterable
            @change="handleStoreChange(false)"
          />
        </el-form-item>
        <el-form-item label="渠道">
          <el-select
            v-model="searchForm.channel"
            placeholder="请选择渠道"
            clearable
            style="width: 200px"
            @change="handleChange"
            :disabled="searchForm.storeCode == ''"
          >
            <el-option label="小程序" :value="0" />
            <el-option label="POS收银" :value="1" disabled />
            <el-option label="抖音" :value="2" disabled />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 日期选择区域 -->
    <div class="date-section">
      <div class="date-actions">
        <el-button
          type="primary"
          size="mini"
          @click="handleEditPrice"
          v-if="!isEditing"
          :disabled="searchForm.storeCode == ''"
          >编辑价目表</el-button
        >
        <el-button size="mini" type="primary" @click="handleSavePrice" v-if="isEditing">保存价目表</el-button>
        <el-button size="mini" @click="handleCancel" v-if="isEditing">取消</el-button>
      </div>

      <!-- 时间选择区域 -->
      <div class="time-selection">
        <!-- 周期选择 -->
        <div class="selection-group">
          <div class="selection-content">
            <el-radio-group v-model="selectedWeekday" @change="updateTableData" size="mini">
              <div class="date-item" v-for="(day, index) in weekDays" :key="index">
                <el-radio-button :label="day.id" :disabled="searchForm.storeCode == ''">
                  {{ day.label }}
                </el-radio-button>
                <div class="status-dot" :class="{ 'has-time': day.isHaveTimeSlot == 1 }"></div>
              </div>
              <div class="date-item" v-for="(date, index) in specialDates" :key="date.id">
                <el-radio-button :disabled="searchForm.storeCode == ''" :label="date.id">
                  {{ date.label }}
                </el-radio-button>
                <div class="status-dot" :class="{ 'has-time': date.isHaveTimeSlot == 1 }"></div>
              </div>
            </el-radio-group>
          </div>
        </div>
      </div>

      <div class="special-date-actions">
        <el-button type="primary" @click="handleCopyToOtherDates" size="mini" v-if="isEditing" :disabled="!canCopy()"
          >复制到其他日期</el-button
        >
        <!-- <el-button type="primary" @click="handleCopyToOtherChannels">复制到其他渠道</el-button> -->
      </div>
    </div>

    <!-- 价格表格区域 -->
    <div class="price-table">
      <el-table :data="tableData" border style="width: 100%" :span-method="handleSpanMethod">
        <el-table-column prop="timeSlot" label="时段" min-width="300" align="center">
          <template #default="{ row }">
            {{ row.timeSlot }}
            <el-button
              v-if="isEditing"
              link
              type="primary"
              @click="handleAddTimeSlot(row)"
              size="small"
              style="margin-left: 10px;"
              >添加套餐</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="消费时长" min-width="200" align="center">
          <template #default="{ row }">
            <template v-if="isEditing">
              <el-input
                v-model="row.duration"
                @change="changeDuration(row)"
                :class="{
                  request: row.duration ? false : true,
                }"
              >
                <template #suffix>
                  小时
                </template>
              </el-input>
            </template>
            <template v-else>
              {{ row.duration ? row.duration + '小时' : '未设置' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="方案名称" min-width="200" align="center">
          <template #default="{ row }">
            <template v-if="isEditing">
              <el-input
                v-model="row.name"
                @change="updateOneStatus()"
                maxlength="10"
                :class="{
                  request: row.name ? false : true,
                }"
              />
            </template>
            <template v-else>
              {{ row.name || '未设置' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="isLowConsume" label="是否超免" min-width="200" align="center">
          <template #default="{ row }">
            <template v-if="isEditing">
              <el-select
                v-model="row.isLowConsume"
                placeholder="请选择"
                @change="updateOneStatus()"
                :class="{
                  request: row.isLowConsume == '1' || row.isLowConsume == '0' ? false : true,
                }"
              >
                <el-option label="是" value="1" />
                <el-option label="否" value="0" />
              </el-select>
            </template>
            <template v-else>
              {{ row.isLowConsume == '1' ? '是' : row.isLowConsume == '0' ? '否' : '未设置' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="productCode" label="套餐" min-width="300" align="center">
          <template #default="{ row }">
            <template v-if="isEditing && row.isLowConsume == '0'">
              <el-select
                v-model="row.productCode"
                placeholder="请选择套餐"
                @change="handlePackageChange(row)"
                clearable
                @clear="handleClearPackage(row)"
              >
                <el-option
                  v-for="pkg in packageOptions"
                  :key="pkg.comboCode"
                  :label="pkg.comboName"
                  :value="pkg.comboCode"
                />
              </el-select>
            </template>
            <template v-else>
              {{ row.isLowConsume == '1' ? '' : row.productName || '未设置' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="productContent" label="套餐内容" min-width="400" align="center">
          <template #default="{ row }">
            <div class="detail-content">
              <div
                class="detail-item"
                v-if="row.goodsDetailInfo.requiredContents && row.goodsDetailInfo.requiredContents.length > 0"
              >
                <span class="label">必选商品：</span>
                <div v-for="item in row.goodsDetailInfo.requiredContents" :key="item.id">
                  <div class="content" v-if="item.name">
                    <span v-if="item.name">{{ item.name }}</span>
                    <span v-if="item.spec">({{ item.spec }})</span>
                    <span v-if="item.count">*{{ item.count }}</span>
                    <span v-if="item.unit">{{ item.unit }}</span>
                  </div>
                </div>
              </div>
              <div v-if="row.goodsDetailInfo.optionContents && row.goodsDetailInfo.optionContents.length > 0">
                <span class="label">可选方案：</span>
                <div v-for="item in row.goodsDetailInfo.optionContents" :key="item.id">
                  <div style="font-weight: 500; margin-bottom: 10px;" v-if="item.name">{{ item.name }}:</div>
                  <div class="content" v-for="(ii, i) in item.products" :key="i">
                    <span v-if="ii.name">{{ ii.name }}</span>
                    <span v-if="ii.spec">({{ ii.spec }})</span>
                    <span v-if="ii.count">*{{ ii.count }}</span>
                    <span v-if="ii.unit">{{ ii.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 动态生成包厢价格列 -->
        <template v-for="roomType in boxTypeList" :key="roomType.value">
          <el-table-column :label="roomType.label + '售价'" min-width="220" align="center">
            <template #default="{ row }">
              <template v-if="isEditing">
                <div style="padding: 10 0px;">
                  <el-input
                    style="width: 90%;"
                    :model-value="getRoomPrice(row, roomType.value)"
                    @update:model-value="value => handlePriceChange(row, roomType.value, 'price', value)"
                    @change="updateOneStatus()"
                    :class="{
                      request: getPriceColor(row, 'price') || comparePrice(row, roomType.value),
                    }"
                  >
                    <template #suffix> 元/间 </template>
                  </el-input>
                  <div v-if="row.isLowConsume == '0' && row.productCode">
                    商品费：{{ row.goodsPrice }}；房费：{{
                      getRoomPrice(row, roomType.value) - row.goodsPrice > 0
                        ? getRoomPrice(row, roomType.value) - row.goodsPrice
                        : 0
                    }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="getRoomPrice(row, roomType.value)">
                  <div>{{ getRoomPrice(row, roomType.value) }}元/间</div>
                  <div v-if="row.isLowConsume == '0' && row.productCode">
                    商品费：{{ row.goodsPrice }}；房费：{{
                      getRoomPrice(row, roomType.value) - row.goodsPrice > 0
                        ? getRoomPrice(row, roomType.value) - row.goodsPrice
                        : 0
                    }}
                  </div>
                </div>
                <div v-else>
                  <div>未设置</div>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :label="roomType.label + '门市价'" min-width="220" align="center">
            <template #default="{ row }">
              <template v-if="isEditing">
                <div style="padding: 10 0px;">
                  <el-input
                    style="width: 90%;"
                    :model-value="getRoomOriginalPrice(row, roomType.value)"
                    @update:model-value="value => handlePriceChange(row, roomType.value, 'showPrice', value)"
                    @change="updateOneStatus()"
                    :class="{
                      request: comparePrice(row, roomType.value),
                    }"
                  >
                    <template #suffix> 元/间 </template>
                  </el-input>
                  <div v-if="row.isLowConsume == '0' && row.productCode">
                    商品费：{{ row.goodsPrice }}；房费：{{
                      getRoomOriginalPrice(row, roomType.value) - row.goodsPrice > 0
                        ? getRoomOriginalPrice(row, roomType.value) - row.goodsPrice
                        : 0
                    }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="getRoomOriginalPrice(row, roomType.value)">
                  <div>{{ getRoomOriginalPrice(row, roomType.value) }}元/间</div>
                  <div v-if="row.isLowConsume == '0' && row.productCode">
                    商品费：{{ row.goodsPrice }}；房费：{{
                      getRoomOriginalPrice(row, roomType.value) - row.goodsPrice > 0
                        ? getRoomOriginalPrice(row, roomType.value) - row.goodsPrice
                        : 0
                    }}
                  </div>
                </div>
                <div v-else>
                  <div>未设置</div>
                </div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" width="100" fixed="right" align="center">
          <template #default="{ row }">
            <el-button link type="danger" @click="handleDeleteRow(row)" v-if="isEditing">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="detailVisible" title="套餐内容详情" width="50%">
      <div class="detail-content">
        <!-- <div class="detail-item">
          <span class="label">套餐名称：</span>
          <span class="content">{{ goodsDetailInfo.comboName }}</span>
        </div> -->
        <div class="detail-item" v-if="goodsDetailInfo.requiredContents.length > 0">
          <span class="label">必选商品：</span>
          <div v-for="item in goodsDetailInfo.requiredContents" :key="item.id">
            <div class="content" v-if="item.name">
              <span v-if="item.name">{{ item.name }}</span>
              <span v-if="item.spec">({{ item.spec }})</span>
              <span v-if="item.count">*{{ item.count }}</span>
              <span v-if="item.unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div v-if="goodsDetailInfo.optionContents.length > 0">
          <span class="label">可选方案：</span>
          <div v-for="item in goodsDetailInfo.optionContents" :key="item.id">
            <div style="font-weight: 500; margin-bottom: 10px;" v-if="item.name">{{ item.name }}:</div>
            <div class="content" v-for="(ii, i) in item.products" :key="i">
              <span v-if="ii.name">{{ ii.name }}</span>
              <span v-if="ii.spec">({{ ii.spec }})</span>
              <span v-if="ii.count">*{{ ii.count }}</span>
              <span v-if="ii.unit">{{ ii.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

  <!-- 复制到其他日期弹窗 -->
  <el-dialog
    v-model="copyDialogVisible"
    title="复制到其他日期"
    width="500px"
    :before-close="handleCopyDialogClose"
    v-if="copyDialogVisible"
  >
    <div class="copy-dialog-content">
      <!-- 警告提示 -->
      <div class="warning-message">
        价目表只能复制到相同时段的日期，复制后该日期原有价目表被覆盖，请及时检查和确认。
      </div>

      <!-- 当前选择的日期 -->
      <div class="current-date">当前设置日期 {{ getCurrentDateDisplay }}</div>

      <!-- 复制目标选择 -->
      <div class="copy-target">
        <div class="copy-title">复制到的日期</div>
        <el-checkbox-group v-model="copyTarget">
          <div class="weekday-list">
            <el-checkbox v-for="day in weekDays" :key="day.value" :disabled="isNotCanCopy(day)" :label="day.id">
              {{ day.label }}
            </el-checkbox>
          </div>
          <div class="special-date-list" v-if="specialDates.length > 0">
            <el-checkbox v-for="date in specialDates" :key="date.id" :label="date.label" :disabled="isNotCanCopy(date)">
              {{ date.label }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCopyDialogClose">取消</el-button>
        <el-button type="primary" @click="handleCopyConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 添加套餐详情抽屉 -->
  <el-drawer v-model="drawerVisible" title="套餐详情" size="30%" :destroy-on-close="true">
    <div class="package-detail">
      <pre>{{ currentPackageContent }}</pre>
    </div>
  </el-drawer>
</template>

<script>
import _ from 'lodash'
import API from '../../service/api'
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import commonApi from '@/api'

export default defineComponent({
  name: 'PriceList',
  setup() {
    // 搜索表单数据
    const searchForm = reactive({
      storeCode: '',
      channel: 0,
    })

    let detailVisible = ref(false)
    let goodsDetailInfo = ref({})
    const handleDetail = row => {
      detailVisible.value = true
      goodsDetailInfo.value = JSON.parse(row.productContent)
    }

    // 门店选项
    const storeOptions = ref([])

    // 套餐选项（需要从接口获取）
    const packageOptions = ref([])
    //获取包厢套餐
    const getPackageList = async () => {
      const res = await API.getComboList({
        storeCode: searchForm.storeCode,
      })
      if (res.code == 200) {
        packageOptions.value = res.data.records
      } else {
        ElMessage.error(res.msg)
      }
    }

    const handlePackageChange = row => {
      row.productContent = JSON.stringify(
        packageOptions.value.find(pkg => pkg.comboCode === row.productCode)?.comboContent,
      )
      row.goodsDetailInfo = JSON.parse(row.productContent)
      row.goodsPrice = packageOptions.value.find(pkg => pkg.comboCode === row.productCode)?.retailPrice
    }

    // 选中的星期或特殊日期
    const selectedWeekday = ref('1')

    // 特殊日期数据
    const specialDates = ref([])

    // 选中的特殊日期
    const selectedSpecialDate = ref(null)

    // 表格数据
    const tableData = ref([])

    // 复制弹窗相关
    const copyDialogVisible = ref(false)

    const copyTarget = ref([])

    // 计算当前选中日期的显示
    const getCurrentDateDisplay = computed(() => {
      if (!selectedWeekday.value) return ''

      // 如果是周几
      const weekday = weekDays.value.find(day => day.value === selectedWeekday.value)
      if (weekday) return weekday.label

      // 如果是特殊日期
      const specialDate = specialDates.value.find(date => date.id.toString() === selectedWeekday.value.toString())
      if (specialDate) return `${specialDate.startDate} - ${specialDate.endDate}`

      return ''
    })

    const boxTypeList = ref([])
    //获取包厢类型数据
    const getRoomTypeList = async () => {
      const res = await API.getBoxTypeList({
        storeCode: searchForm.storeCode,
      })
      if (res.code == 200) {
        boxTypeList.value = res.data.map(item => ({
          value: item.code,
          label: item.name,
        }))
      } else {
        ElMessage.error(res.msg)
      }
    }

    // 存储API返回的原始数据
    const apiData = ref(null)
    // 添加编辑状态下的临时数据存储
    const editingData = ref({})

    // 处理接口返回的数据
    const handleApiData = data => {
      try {
        if (!data?.voList) return
        // 更新表格数据
        let obj = {}
        data.voList.forEach(item => {
          //遍历每天的
          if (item.timeSlotVoList.length != 0) {
            let list = []
            //遍历每天时段的
            item.timeSlotVoList.forEach(a => {
              let obj = {
                timeSlotCode: a.timeSlotCode,
                timeSlot: a.timeSlot,
                goodsDetailInfo: {},
              }
              if (a.itemVOList.length == 0) {
                list.push(obj)
              } else {
                a.itemVOList.forEach(b => {
                  list.push({
                    ...b,
                    duration: convertMinutesToHours(b.duration),
                    timeSlotCode: a.timeSlotCode,
                    timeSlot: a.timeSlot,
                    goodsPrice: b.goodsPrice,
                    goodsDetailInfo: b.productContent ? JSON.parse(b.productContent) : {},
                  })
                })
              }
            })

            item.timeSlotVoList = list
          }

          if (item.dayType === '1') {
            obj[item.dayOfWeek] = item
          } else {
            obj[item.specialDate] = item
          }
        })
        apiData.value = obj
        updateTableData()
      } catch (error) {
        console.error('获取数据失败：', error)
      }
    }

    const updateTableData = () => {
      tableData.value = apiData.value[selectedWeekday.value]?.timeSlotVoList
    }

    // 将分钟转换为小时
    const convertMinutesToHours = minutes => {
      if (!minutes) return ''
      const hours = (minutes / 60).toFixed(0)
      return `${hours}`
    }

    // 获取包厢价格的方法
    const getRoomPrice = (row, roomTypeCode) => {
      const price = row.roomTypePriceList?.find(p => p.roomTypeCode === roomTypeCode)

      return price ? price.price : ''
    }

    const getRoomOriginalPrice = (row, roomTypeCode) => {
      const price = row.roomTypePriceList?.find(p => p.roomTypeCode === roomTypeCode)
      return price ? price.showPrice : ''
    }
    // 监听选中日期变化
    // watch(selectedWeekday, (newVal, oldVal) => {
    //   updateTableData()
    // })

    const handleChange = async () => {
      getPackageList()
      let res = await API.getStoreSchemeList({
        storeCode: searchForm.storeCode,
        channel: searchForm.channel,
      })

      if (res.code == '200') {
        handleApiData(res.data)
        updateEveryStatus()
      } else {
        ElMessage.error(res.msg)
      }
    }
    // 搜索方法
    const handleStoreChange = async (isReset = false) => {
      try {
        selectedWeekday.value = !isReset ? 1 : selectedWeekday.value
        console.log(selectedWeekday.value)

        // 获取包厢类型列表
        await getRoomTypeList()
        // 获取日期列表
        await getDateList()
        // 获取包厢套餐
        getPackageList()
        let res = await API.getStoreSchemeList({
          storeCode: searchForm.storeCode,
          channel: searchForm.channel,
        })

        if (res.code == '200') {
          handleApiData(res.data)
          updateEveryStatus()
        } else {
          ElMessage.error(res.msg)
        }
      } catch (error) {
        console.error('获取数据失败：', error)
        ElMessage.error('获取数据失败')
      }
    }
    // 周期数据
    const weekDays = ref([
      { label: '周一', id: '1' },
      { label: '周二', id: '2' },
      { label: '周三', id: '3' },
      { label: '周四', id: '4' },
      { label: '周五', id: '5' },
      { label: '周六', id: '6' },
      { label: '周日', id: '7' },
    ])
    //获取日期列表
    const getDateList = async () => {
      const res = await API.getDateList({
        storeCode: searchForm.storeCode,
      })
      if (res.code == 200) {
        specialDates.value = res.data.specialDates.map((a, index) => ({
          label: a.specialDate,
          id: a.specialDate,
          isHaveTimeSlot: 0,
        }))
        weekDays.value = weekDays.value.map(day => ({
          ...day,
          isHaveTimeSlot: res.data.dayOfWeeks.find(a => a.dayOfWeek == day.id).isHaveTimeSlot,
        }))
      } else {
        ElMessage.error(res.msg)
      }
    }

    const hasDelete = row => {
      let index = tableData.value.findIndex(a => a == row)

      if (index == 0) return false

      if (tableData.value[index - 1].timeSlot == row.timeSlot) {
        return true
      }
      return false
    }

    // 重置方法
    const handleReset = () => {
      searchForm.storeCode = ''
      searchForm.channel = null
    }

    // 编辑价目表
    const handleEditPrice = () => {
      isEditing.value = true
      // 初始化当前数据
      // 到临时存储
    }
    const changeDuration = row => {
      if (isNaN(Number(row.duration))) {
        ElMessage.error('请输入正确的消费时长')
        row.duration = ''
      }
      //只能是整数
      if (!Number.isInteger(Number(row.duration))) {
        ElMessage.error('请输入正确的消费时长')
        row.duration = ''
      }
      //将所有时段相同的时段的时长设置为相同的
      let sameTimeSlot = tableData.value.filter(a => a.timeSlot == row.timeSlot)
      sameTimeSlot.forEach(a => {
        a.duration = row.duration
      })
      updateOneStatus()
    }

    const getPriceColor = (row, roomType) => {
      if (
        !row.roomTypePriceList ||
        row.roomTypePriceList.length == 0 ||
        row.roomTypePriceList.filter(b => b[roomType] && b[roomType] != 0).length == 0
      ) {
        return true
      }
      return false
    }
    const updateOneStatus = () => {
      for (let j of tableData.value) {
        if (!j.duration) {
          return setWeekDayStatus(selectedWeekday.value, 0)
        }
        if (!j.name || j.name == '') {
          return setWeekDayStatus(selectedWeekday.value, 0)
        }
        if (j.isLowConsume != '1' && j.isLowConsume != '0') {
          return setWeekDayStatus(selectedWeekday.value, 0)
        }

        if (
          !j.roomTypePriceList ||
          j.roomTypePriceList.length == 0 ||
          j.roomTypePriceList.filter(b => !b.price || b.price == 0).length == j.roomTypePriceList.length
        ) {
          return setWeekDayStatus(selectedWeekday.value, 0)
        }

        if (j.roomTypePriceList.find(b => b.showPrice && +b.price >= +b.showPrice)) {
          return setWeekDayStatus(selectedWeekday.value, 0)
        }
      }

      setWeekDayStatus(selectedWeekday.value, 1)
    }

    const setWeekDayStatus = (id, isHaveTimeSlot = 0) => {
      let week = weekDays.value.find(a => a.id == id)
      if (week) {
        week.isHaveTimeSlot = isHaveTimeSlot
        return
      }
      let special = specialDates.value.find(a => a.id == id)
      if (special) {
        special.isHaveTimeSlot = isHaveTimeSlot
      }
    }

    const updateEveryStatus = () => {
      for (let i of Object.keys(apiData.value)) {
        let timeSlotVoList = apiData.value[i].timeSlotVoList
        let tag = true
        for (let j of timeSlotVoList) {
          if (!j.duration) {
            tag = false
            break
          }
          if (!j.name) {
            tag = false
            break
          }
          if (j.isLowConsume != '1' && j.isLowConsume != '0') {
            tag = false
            break
          }

          if (
            j.roomTypePriceList.length == 0 ||
            j.roomTypePriceList.filter(b => !b.price || b.price === 0).length == j.roomTypePriceList.length
          ) {
            tag = false
            break
          }

          if (j.roomTypePriceList.find(b => b.showPrice && +b.price >= +b.showPrice)) {
            tag = false
            break
          }
        }

        if (tag) {
          setWeekDayStatus(i, 1)
        } else {
          setWeekDayStatus(i)
        }
      }
    }

    //校验
    const checkTableData = () => {
      //遍历tableData

      for (let i of Object.keys(apiData.value)) {
        let timeSlotVoList = apiData.value[i].timeSlotVoList

        let name = weekDays.value.find(a => a.id == i)?.label || specialDates.value.find(a => a.id == i)?.label

        for (let j of timeSlotVoList) {
          if (!j.duration) {
            ElMessage.error(`请添加${name}${j.timeSlot}的消费时长`)
            return Promise.reject()
          }
          if (!j.name) {
            ElMessage.error(`请添加${name}${j.timeSlot}的方案名称`)
            return Promise.reject()
          }
          if (j.isLowConsume != '1' && j.isLowConsume != '0') {
            ElMessage.error(`请添加${name}${j.timeSlot}的类型`)
            return Promise.reject()
          }
          if (
            !j.roomTypePriceList ||
            j.roomTypePriceList.length == 0 ||
            j.roomTypePriceList.filter(b => !b.price || b.price == 0).length == j.roomTypePriceList.length
          ) {
            ElMessage.error(`请添加${name}${j.timeSlot}的房型价格`)
            return Promise.reject()
          }

          if (j.roomTypePriceList.find(b => b.showPrice && +b.price >= +b.showPrice)) {
            ElMessage.error(`${name}${j.timeSlot}的门市价不能小于原价`)
            return Promise.reject()
          }
        }
      }
      return Promise.resolve()
    }

    // 保存价目表
    const handleSavePrice = async () => {
      try {
        await checkTableData()

        // 构造保存的数据结构
        const saveData = {
          storeCode: searchForm.storeCode,
          channel: searchForm.channel,
          voList: [],
        }
        let list = []

        Object.keys(apiData.value).forEach(key => {
          let obj = {}
          obj.dayType = key <= 7 ? '1' : '2'
          obj.dayOfWeek = key <= 7 ? key : null
          obj.specialDate = key
          obj.timeSlotVoList = []
          apiData.value[key].timeSlotVoList.forEach(item => {
            let index = obj.timeSlotVoList.findIndex(a => a.timeSlotCode === item.timeSlotCode)
            if (index == -1) {
              obj.timeSlotVoList.push({
                timeSlotCode: item.timeSlotCode,
                timeSlot: item.timeSlot,
                itemVOList: [
                  {
                    goodsPrice: item.goodsPrice,
                    duration: Number(item.duration) * 60,
                    name: item.name,
                    isLowConsume: item.isLowConsume,
                    productCode: item.isLowConsume == '1' ? '' : item.productCode,
                    roomTypePriceList: item.roomTypePriceList,
                    schemeCode: item.schemeCode,
                  },
                ],
              })
            } else {
              obj.timeSlotVoList[index].itemVOList.push({
                goodsPrice: item.goodsPrice,
                duration: Number(item.duration) * 60,
                name: item.name,
                isLowConsume: item.isLowConsume,
                productCode: item.isLowConsume == '1' ? '' : item.productCode,
                roomTypePriceList: item.roomTypePriceList,
                schemeCode: item.schemeCode,
              })
            }
          })
          list.push(obj)
        })
        saveData.voList = list

        // 调用保存接口
        const res = await API.saveStoreScheme(saveData)
        if (res.code === '200') {
          ElMessage.success('保存成功')
          isEditing.value = false
          // 刷新数据
          handleStoreChange()
        } else {
          ElMessage.error(res.msg || '保存失败')
        }
      } catch (error) {
        console.error('保存失败：', error)
      }
    }

    // 取消编辑
    const handleCancel = () => {
      isEditing.value = false
      // 清空临时存储的数据
      editingData.value = {}
      handleStoreChange(true) // 重置数据
    }

    // 复制到其他日期
    const handleCopyToOtherDates = () => {
      if (canCopy()) {
        // 复制到其他周
        weekDays.value.forEach(day => (day.checked = false))
        copyDialogVisible.value = true
        copyTarget.value = []
      } else {
        return ElMessage.error('当前无法复制')
      }
    }

    //是否可以复制
    const isNotCanCopy = day => {
      if (day.id == selectedWeekday.value) {
        return true
      }
      //如果时段不同也不能复制

      //获取当前选择的日期的时段
      let currentSet = new Set()
      tableData.value.forEach(item => {
        currentSet.add(item.timeSlot)
      })
      //获取其他日期的时段
      let otherSet = new Set()
      if (!apiData.value[day.id]) return true

      apiData.value[day.id].timeSlotVoList?.forEach(item => {
        otherSet.add(item.timeSlot)
      })

      //对比两个set是否一样
      const _isSameSet = (s1, s2) => {
        if (s1.size !== s2.size) {
          return false
        }
        return [...s1].every(i => s2.has(i))
      }
      return !_isSameSet(currentSet, otherSet)
    }

    //判断当前时段是否可以复制
    const canCopy = () => {
      let week = weekDays.value.find(a => a.id == selectedWeekday.value)
      if (week) {
        return week.isHaveTimeSlot == 1
      }
      let special = specialDates.value.find(a => a.id == selectedWeekday.value)
      if (special) {
        return special.isHaveTimeSlot == 1
      }
    }

    // 复制到其他渠道
    const handleCopyToOtherChannels = () => {
      // TODO: 实现复制到其他渠道逻辑
    }

    // 添加时段
    const handleAddTimeSlot = row => {
      // 创建新的套餐行，继承当前时段的信息
      const newRow = {
        timeSlotCode: row.timeSlotCode,
        timeSlot: row.timeSlot,
        duration: row.duration,
        name: '',
        isLowConsume: '0',
        productCode: '',
        productContent: '',
        roomTypePriceList: [], // 存储房型价格列表
        goodsDetailInfo: {},
      }

      // 在表格数据中找到当前时段的位置
      const currentIndex = tableData.value.findLastIndex(item => item.timeSlotCode === row.timeSlotCode)

      // 在当前时段后插入新行
      tableData.value.splice(currentIndex + 1, 0, newRow)
    }

    // 删除行
    const handleDeleteRow = row => {
      ElMessageBox.confirm('确认删除该时段吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 找到要删除的行的索引
          const index = tableData.value.findIndex(item => item == row)

          let newRow = {
            timeSlotCode: row.timeSlotCode,
            timeSlot: row.timeSlot,
            duration: '',
            packageName: '',
            productCode: '',
            productName: '',
            isLowConsume: '0',
            productCode: '',
            productContent: '',
            roomPrices: [],
            goodsDetailInfo: {},
          }
          if (tableData.value.length == 1) {
            return tableData.value.splice(index, 1, newRow)
          }

          if (index == 0) {
            if (tableData.value[index + 1].timeSlot == row.timeSlot) {
              return tableData.value.splice(index, 1)
            } else {
              return tableData.value.splice(index, 1, newRow)
            }
          }

          if (index != 0) {
            if (
              tableData.value[index - 1]?.timeSlot == row.timeSlot ||
              tableData.value[index + 1]?.timeSlot == row.timeSlot
            ) {
              return tableData.value.splice(index, 1)
            } else {
              return tableData.value.splice(index, 1, newRow)
            }
          }
        })
        .catch(() => {
          // 取消删除时不做任何处理
        })
    }

    // 关闭复制弹窗
    const handleCopyDialogClose = () => {
      copyDialogVisible.value = false
    }

    // 确认复制
    const handleCopyConfirm = async () => {
      handleCopyDialogClose()
      copyTarget.value.forEach(item => {
        let newData = apiData.value[selectedWeekday.value]

        let orignData = apiData.value[item]

        apiData.value[item] = _.cloneDeep({
          ...newData,

          timeSlotVoList: newData.timeSlotVoList.map(a => ({
            ...a,
            timeSlotCode: orignData.timeSlotVoList.find(b => b.timeSlot === a.timeSlot)?.timeSlotCode,
            schemeCode: '',
          })),
        })

        setWeekDayStatus(item, 1)
      })
      // 调用复制接口
    }

    // 添加处理单元格合并的方法
    const handleSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      // 时段列和消费时长列都需要合并
      if (columnIndex === 0 || columnIndex === 1) {
        const prevRow = tableData.value[rowIndex - 1]

        // 如果是第一行，或者与前一行的时段不同，则开始一个新的合并
        if (!prevRow || prevRow.timeSlotCode !== row.timeSlotCode) {
          // 计算当前时段有多少行需要合并
          const rowSpan = tableData.value.filter(
            (item, index) => index >= rowIndex && item.timeSlotCode === row.timeSlotCode,
          ).length
          return {
            rowspan: rowSpan,
            colspan: 1,
          }
        } else {
          // 其他行不显示
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    }

    const isEditing = ref(false)

    //比较价格
    const comparePrice = (row, roomTypeCode) => {
      let priceObj = row.roomTypePriceList?.find(p => p.roomTypeCode === roomTypeCode)
      if (!priceObj) {
        return false
      }
      if (priceObj.showPrice && +priceObj.price >= +priceObj.showPrice) {
        return true
      }
      return false
    }

    // 添加处理价格变化的方法
    const handlePriceChange = (row, roomTypeCode, priceType, value) => {
      //如果vaelu不是数字
      if (isNaN(value)) {
        ElMessage.error('请输入正确的数字')
        return
      }
      // 确保 roomPrices 数组存在
      if (!row.roomTypePriceList) {
        row.roomTypePriceList = []
      }

      // 查找对应房型的价格对象
      let priceObj = row.roomTypePriceList.find(p => p.roomTypeCode === roomTypeCode)

      // 如果不存在，创建新的价格对象
      if (!priceObj) {
        priceObj = {
          roomTypeCode: roomTypeCode,
          price: '',
          showPrice: '',
        }
        row.roomTypePriceList.push(priceObj)
      }

      if ((value === '0' || value === 0) && priceType == 'price') {
        priceObj.price = ''
        return ElMessage.error('售价不能为0')
      }

      // 更新价格
      priceObj[priceType] = value
    }

    // 抽屉相关
    const drawerVisible = ref(false)
    const currentPackageContent = ref('')

    // 显示套餐详情
    const showPackageDetail = row => {
      try {
        currentPackageContent.value = JSON.parse(row.productContent)
        drawerVisible.value = true
      } catch (e) {
        currentPackageContent.value = row.productContent
        drawerVisible.value = true
      }
    }

    onMounted(async () => {
      // 获取门店数据
      const res = await commonApi.getNewShop({})
      storeOptions.value = res.data
        .map(item => ({
          value: item.code,
          label: `${item.code}--${item.name}`,
        }))
        .sort((a, b) => Number(a.value.slice(1)) - Number(b.value.slice(1)))
    })

    const handleClearPackage = row => {
      row.productCode = ''
      row.goodsPrice = ''
      row.goodsDetailInfo = {}
    }

    return {
      searchForm,
      storeOptions,
      packageOptions,
      selectedWeekday,
      specialDates,
      selectedSpecialDate,
      tableData,
      handleStoreChange,
      handleReset,
      handleEditPrice,
      handleSavePrice,
      handleCancel,
      handleCopyToOtherDates,
      handleCopyToOtherChannels,
      handleAddTimeSlot,
      handleDeleteRow,
      copyDialogVisible,
      weekDays,
      getCurrentDateDisplay,
      handleCopyDialogClose,
      handleCopyConfirm,
      handleApiData,
      updateTableData,
      apiData,
      convertMinutesToHours,
      handleSpanMethod,
      boxTypeList,
      getRoomPrice,
      getRoomOriginalPrice,
      isEditing,
      handlePriceChange,
      handlePackageChange,
      drawerVisible,
      currentPackageContent,
      showPackageDetail,
      isNotCanCopy,
      updateOneStatus,
      copyTarget,
      handleClearPackage,
      canCopy,
      handleChange,
      hasDelete,
      changeDuration,
      getPriceColor,
      handleDetail,
      detailVisible,
      goodsDetailInfo,
      comparePrice,
    }
  },
})
</script>

<style lang="scss" scoped>
.request {
  border: 1px solid red;
}
.detail-content {
  max-height: 420px;
  overflow-y: auto;
  padding: 0 10px;
  .label {
    line-height: 30px;
    font-weight: 600;
  }
  .content {
    line-height: 26px;
  }
}
.price-list-container {
  padding: 20px;

  .search-section {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .date-section {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    .date-actions {
      margin-bottom: 20px;
    }

    .time-selection {
      margin-bottom: 20px;

      .selection-group {
        margin-bottom: 16px;

        .selection-title {
          font-size: 14px;
          color: #606266;
          margin-bottom: 12px;
        }

        .selection-content {
          :deep(.el-radio-group) {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .date-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin-left: 20px;

              .el-radio-button__inner {
                border-radius: 4px;
              }

              .status-dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-top: 4px;
                background-color: #dcdfe6;
                transition: background-color 0.3s;

                &.has-time {
                  background-color: #409eff;
                }
              }

              .el-icon-delete {
                position: absolute;
                right: -16px;
                top: 50%;
                transform: translateY(-50%);
                color: #f56c6c;

                &:hover {
                  color: #f78989;
                }
              }
            }
          }
        }
      }
    }
  }

  .special-date-actions {
    display: flex;
    gap: 10px;
  }

  .price-table {
    background: #fff;
    padding: 20px;
    border-radius: 4px;

    .price-note {
      font-size: 12px;
      color: #999;
      margin-top: 4px;
    }
  }
}

.copy-dialog-content {
  .warning-message {
    background-color: #fff9f9;
    color: #f56c6c;
    padding: 12px 16px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
  }

  .current-date {
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
  }

  .copy-target {
    .copy-title {
      font-size: 14px;
      color: #606266;
      margin-bottom: 12px;
    }

    .weekday-list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;

      :deep(.el-checkbox) {
        margin-right: 0;
      }
    }

    .special-date-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      :deep(.el-checkbox) {
        margin-right: 0;
      }
    }
  }
}

.package-detail {
  padding: 20px;

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    line-height: 1.5;
  }
}
</style>
